import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';

import {
  CUSTOM_FIELD_TYPE_SHORT_ANSWER,
  CUSTOM_FIELD_TYPE_LONG_ANSWER,
  CUSTOM_FIELD_TYPE_CHECKBOX,
  CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
  CUSTOM_FIELD_TYPE_SINGLE_SELECT,
  CUSTOM_FIELD_TYPE_MULTI_SELECT,
  CUSTOM_FIELD_TYPE_USER_SELECT,
  CUSTOM_FIELD_TYPE_MULTI_USER_SELECT,
  CUSTOM_FIELD_TYPE_DATE,
  CUSTOM_FIELD_TYPE_TEXT,
} from 'src/app/constants';
import { flattenAdditionalIdpFields } from 'src/app/utils/utils';
import { AuthService } from 'src/services/auth.service';

import { ActionDropdownComponent, ActionDropdownType } from '../action-dropdown/action-dropdown.component';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  @Input() customFields: any[] = [];
  @Input() addCustomFieldButtonLabel: string;
  @Input() placeholder: string;
  @Input() showPublicOption: boolean;
  @Input() showUserAttributeOption: boolean;
  @Input() showBranchingInput: boolean;
  @Input() showDescription: boolean;
  @Input() contextPrefix: string;

  dropdownOptionFields: string[] = [];

  customFieldTypes = [
    {
      displayName: 'Short answer',
      key: CUSTOM_FIELD_TYPE_SHORT_ANSWER,
    },
    {
      displayName: 'Long answer',
      key: CUSTOM_FIELD_TYPE_LONG_ANSWER,
    },
    {
      displayName: 'Checkbox',
      key: CUSTOM_FIELD_TYPE_CHECKBOX,
    },
    {
      displayName: 'Single select',
      key: CUSTOM_FIELD_TYPE_SINGLE_SELECT,
    },
    {
      displayName: 'Multi select',
      key: CUSTOM_FIELD_TYPE_MULTI_SELECT,
    },
    {
      displayName: 'User select',
      key: CUSTOM_FIELD_TYPE_USER_SELECT,
    },
    {
      displayName: 'Multi user select',
      key: CUSTOM_FIELD_TYPE_MULTI_USER_SELECT,
    },
    {
      displayName: 'Date',
      key: CUSTOM_FIELD_TYPE_DATE,
    },
    {
      displayName: 'Text',
      key: CUSTOM_FIELD_TYPE_TEXT,
    },
    // {
    //   displayName: 'File upload',
    //   key: CUSTOM_FIELD_TYPE_FILE_UPLOAD,
    // },
  ];

  userAttributes: any = [];

  constructor(
    private authService: AuthService,
    private popoverCtrl: PopoverController,
  ) {}

  ngOnInit() {
    if (this.showUserAttributeOption) {
      this.customFieldTypes.push({
        displayName: 'User attribute',
        key: CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
      });

      if (this.authService.profile.additionalIdpFields) {
        this.userAttributes = Object.keys(flattenAdditionalIdpFields(this.authService.profile.additionalIdpFields));
      }
    }
  }

  addCustomField() {
    if (!this.customFields) {
      this.customFields = [];
    }

    // Cool random arbitrary limit on length
    if (this.customFields.length >= 100) {
      return;
    }

    this.customFields.push({
      label: '',
      description: '',
      // value: '',
      type: CUSTOM_FIELD_TYPE_SHORT_ANSWER,
      required: false,
      public: true,
      id: uuidv4(),
      conditions: {
        type: 'simple',
        simple: {},
      },
    });
  }

  async reorderField(index: number) {
    const MOVE_UP = 'Move up';
    const MOVE_DOWN = 'Move down';

    const options: ActionDropdownType[] = [];

    if (index !== 0) {
      options.push({
        label: MOVE_UP,
        icon: 'arrow-up-outline',
      });
    }

    if (index < this.customFields.length - 1) {
      options.push({
        label: MOVE_DOWN,
        icon: 'arrow-down-outline',
      });
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (_index: number, label: string) => {
          popover.dismiss();

          const item = this.customFields.splice(index, 1)[0];
          const newIndex = label === MOVE_UP ? index - 1 : index + 1;
          this.customFields.splice(newIndex, 0, item);
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  deleteCustomField(index: number) {
    this.customFields.splice(index, 1);
  }

  addCustomFieldOption(fieldIndex: number, label: string) {
    if (!label) {
      return;
    }

    if (!this.customFields[fieldIndex].options) {
      this.customFields[fieldIndex].options = [label];
    } else {
      this.customFields[fieldIndex].options.push(label);
    }

    this.dropdownOptionFields = [];
  }

  deleteCustomFieldOption(fieldIndex: number, optionIndex: number) {
    this.customFields[fieldIndex].options.splice(optionIndex, 1);
  }

  toggleConditionsShown(fieldIndex: number) {
    const field = this.customFields[fieldIndex];

    if (this.getConditionsForField(field)?.length) {
      field.conditions.simple[this.getConditionsType(field)] = [];
    } else {
      this.addSimpleCondition(fieldIndex);
    }
  }

  addSimpleCondition(fieldIndex: number) {
    const field = this.customFields[fieldIndex];

    if (!this.getConditionsForField(field)?.length) {
      field.conditions = {
        type: 'simple',
        simple: {
          and: [],
        },
      };
    }

    field.conditions.simple[this.getConditionsType(field)] = [
      ...this.getConditionsForField(field),
      {
        leftValueFrom: '',
        operator: 'equals',
        rightValue: null,
      },
    ];
  }

  removeSimpleCondition(fieldIndex: number, conditionIndex: number) {
    const field = this.customFields[fieldIndex];

    field.conditions.simple[this.getConditionsType(field)].splice(conditionIndex, 1);
  }

  onSimpleConditionFieldChange(event: any, condition: any) {
    // Reset the value when the field changes
    condition.rightValue = null;
  }

  getFieldType(fieldLabel: string): string {
    const field = this.customFields.find((f) => this.getTicketFieldFieldIdPath(f.id) === fieldLabel);

    return field ? field.type : 'text';
  }

  getFieldOptions(fieldLabel: string): string[] {
    const field = this.customFields.find((f) => this.getTicketFieldFieldIdPath(f.id) === fieldLabel);

    return field && (field.type === 'single-select' || field.type === 'multi-select') ? field.options : [];
  }

  compareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }

  getConditionalFieldOptions(i: number) {
    return this.customFields.filter(
      (f, j) => f.type !== 'user-select' && f.type !== 'multi-user-select' && j !== i && f.type !== 'text',
    );
  }

  getTicketFieldFieldIdPath(id: string) {
    return `${this.contextPrefix}['${id}']`;
  }

  getConditionsForField(field: any) {
    return field.conditions?.simple?.[this.getConditionsType(field)] ?? [];
  }

  toggleConditionsType(field: any) {
    if (field.conditions?.simple?.and?.length) {
      field.conditions.simple.or = field.conditions.simple.and;
      field.conditions.simple.and = null;
    } else if (field.conditions?.simple?.or?.length) {
      field.conditions.simple.and = field.conditions.simple.or;
      field.conditions.simple.or = null;
    }
  }

  getConditionsType(field: any) {
    return field.conditions?.simple?.and ? 'and' : 'or';
  }
}
